<template>
  <div class="box">
    <el-container>
      <!-- left aside -->
      <div>
        <el-aside width="245px">
          <div class="aside_box_top">
            <div class="aside_logo">
              <img
                style="width: 50px; height: 50px; margin-top: 10px"
                src="../assets/images/logo.png"
                alt=""
              />
            </div>
            <div class="aside_logofont">后台管理系统</div>
          </div>
          <div class="nav_left">
            <el-menu
              class="el_menu"
              router
              :uniqueOpened="false"
              text-color="#fff"
              background-color="#1d4277"
              active-text-color="#279DFB"
              :default-active="selectPath"
            >
              <template v-for="(item, key) in menus">
                <el-menu-item
                  :key="key"
                  :index="item.url"
                  v-if="item.children === null"
                  @click="savepath(item)"
                >
                  <img class="iconimg" src="../assets/images/home.png" alt="" />
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
                <el-submenu
                  :index="key + ''"
                  :key="key"
                  v-if="item.children !== null"
                >
                  <template #title>
                    <img
                      v-if="item.code == 'root02'"
                      class="iconimg"
                      src="../assets/images/store.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root03'"
                      class="iconimg"
                      src="../assets/images/goods.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root04'"
                      class="iconimg"
                      src="../assets/images/order.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root10'"
                      class="iconimg"
                      src="../assets/images/logistics.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root05'"
                      class="iconimg"
                      src="../assets/images/member.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root06'"
                      class="iconimg"
                      src="../assets/images/business.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root07'"
                      class="iconimg"
                      src="../assets/images/market.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root09'"
                      class="iconimg"
                      src="../assets/images/stting.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root08'"
                      class="iconimg"
                      style="width: 20px; height: 20px"
                      src="../assets/images/dispose.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root12'"
                      class="iconimg"
                      src="../assets/images/journal.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root11'"
                      class="iconimg"
                      src="../assets/images/resource.png"
                      alt=""
                    />
                    <img
                      v-if="item.code == 'root13'"
                      class="iconimg"
                      src="../assets/images/resource.png"
                      alt=""
                    />
                    <span>{{ item.name }}</span>
                    <!-- <span>2</span> -->
                  </template>
                  <div class="el-menu-item-group">
                    <el-menu-item
                      v-for="(item, k) in item.children"
                      :index="item.url"
                      :key="k"
                      @click="savepath(item)"
                    >
                      <template #title>
                        <span>{{ item.name }}</span>
                      </template>
                    </el-menu-item>
                  </div>
                </el-submenu>
              </template>
            </el-menu>
          </div>
          <!-- 1 -->
        </el-aside>
      </div>
      <!-- left aside -->
      <el-container>
        <!-- top herder -->
        <el-header height="70px">
          <div class="content">
            <div class="contentHeader">
              <div class="icon"></div>
              <div class="User">
                <img
                  style="width: 35px; height: 35px"
                  src="../assets/images/headertel.png"
                  alt=""
                />
                <el-popover
                  placement="bottom"
                  :width="175"
                  trigger="hover"
                  title=""
                >
                  <template #reference>
                    <div style="color: #fafafa">个人中心</div>
                  </template>
                  <div class="popover_box">
                    <div class="popover" @click="btnStore">店铺维护情况</div>
                    <div class="popover" @click="dialogVisible = true">
                      上传协议
                    </div>
                    <div class="popover" @click="fileNotice">上传公告</div>
                    <div class="popover" @click="festivalFn">
                      节假日管理
                    </div>
                    <div class="popover">修改密码</div>
                    <div class="popover" @click="exit">退出登录</div>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </el-header>
        <div class="tag_box">
          <div class="tag">
            <el-tag
              v-for="(tag, index) in tags"
              :key="index"
              :closable="tag.url !== '/home'"
              :effect="tag.url === selectPath ? 'dark' : 'light'"
              @click="onClick(tag)"
              @close="handleClose(tag)"
              class="tag-active"
              style="cursor: pointer"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
        <!-- top herder -->
        <!-- center main -->
        <el-main>
          <keep-alive max="1">
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </el-main>
        <!-- center main -->
      </el-container>
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        center
        append-to-body
      >
        <span slot="footer" class="dialog-footer"></span>
        <el-upload
          class="upload-demo"
          :action="fileFile"
          :on-success="fileChange"
          :on-error="btnError"
          accept="MIME_type"
          :headers="headers"
          name="agreement"
          style="text-align: center;"
          ref="upload"
          :multiple="true"
        >
          <el-button size="small" type="primary">上传协议</el-button>
        </el-upload>
        <div
          class="box_text"
          style="font-weight: bold;font-size: 15px;margin-top: 30px;"
        >
          更新协议请使用以下对应文件名称
        </div>
        <div class="box_text">用户协议：privacy_policy.html</div>
        <div class="box_text">商户协议：merchant_agreement.html</div>
        <div class="box_text">平台协议：platform_agreement.html</div>
        <div class="box_text">运费规则：freight_rules.html</div>
      </el-dialog>
      <el-dialog
        title="公告提示"
        :visible.sync="dialogVisible2"
        width="30%"
        center
        append-to-body
      >
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="turnOff" label="是否开启通知:">
            <el-radio-group v-model="form.turnOff">
              <el-radio label="1">开启</el-radio>
              <el-radio label="0">不开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="content">
            <el-input
              placeholder="请填写通知内容"
              v-model="form.content"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="noticeBtn('form')">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="上传节假日时间"
        :visible.sync="timeDialogVisible"
        width="50%"
        center
        append-to-body
      >
        <div class="holiday">
          <div class="textbox">
             <div class="text">设置节假日时间:</div>
          </div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="festival.holidays"
          >
          </el-input>
          <div class="point">时间格式: 2023-05-01,2023-05-02 (多个日期以英文逗号分隔)</div>
        </div>
        <div class="holiday">
          <div class="textbox">
             <div class="text">发货推迟至:</div>
          </div>
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="festival.delayDate"
          >
          </el-input>
          <div class="point">时间格式: 2023-05-01 (填写一个)</div>
        </div>
        <div class="block">
          <span class="demonstration">开始实行时间:</span>
          <el-date-picker
            v-model="festival.startTime"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="timeDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editFestivalTime"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { setStore, getStore, removeStore } from "../utils/storage";
import { userSysPerms,getFestivalTime,setFestivalTime } from "@/api/permission";
import { editNoticeTopMarquee, getNoticeTopMarquee } from "@/api/file";
import url from "@/utils/file";
import { uploadGetStsUrl, maintainStore } from "@/api/store";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      timeDialogVisible: false,
      selectPath: "",
      menus: [],
      tags: [
        {
          url: "/home",
          name: "首页"
        }
      ],
      array: [],
      form: {
        content: "", //通知内容
        turnOff: "0" //是否开启通知
      },
      rules: {
        content: [
          {
            required: true,
            message: "请填写通知提示",
            trigger: "blur"
          }
        ]
      },
      collapsed: false,
      editableTabsValue: "2",
      tabIndex: 2,
      fileFile: url.url_agreement,
      fileData: [],
      privacy_policy: [], // 用户协议
      merchant_agreement: [], // 商户协议
      platform_agreement: [], // 平台协议
      freight_rules: [], // 运费规则
      festival: {
        id: "",
        delayDate: "",
        holidays: "",
        startTime: ""
      }
    };
  },
  computed: {
    //图片上传请求头部
    headers() {
      return {
        Authorization: getStore("token")
      };
    }
  },
  created() {
    let time = 1656659888;
    let a = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.$router.push("/home"); //初始化路由到首页
    window.onunload = function() {
      //离开页面后初始化tab栏
      setStore("selectPath", "/home");
      setStore("selectPathName", "首页");
    };
    this.homeList();
    this.selectPath = getStore("selectPath");
  },

  methods: {
    festivalFn() {
      this.timeDialogVisible = true;
      getFestivalTime().then(res => {
        this.festival.id = res.data.id;
        this.festival.holidays = res.data.holidays;
        this.festival.startTime = res.data.startTime;
        this.festival.delayDate = res.data.delayDate;
      })
    },

    editFestivalTime() {
      this.timeDialogVisible = false;
      console.log(this.festival.startTime)
      setFestivalTime(this.festival).then(res => {
        console.log(res)
      })
    },

    fileChange(img) {
      this.$notify({
        title: "成功",
        message: "协议上传成功",
        type: "success"
      });
    },

    btnError(img) {
      this.$notify.error({
        title: "错误",
        message: "所选协议文件名不正确"
      });
    },

    handleClose(done) {
      this.$refs["upload"].clearFiles();
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    homeList() {
      // 获取路由
      userSysPerms().then(res => {
        this.menus = res.data;
      });
      // 获取路由
      let url = getStore("selectPath");
      let name = getStore("selectPathName");
      this.tags.forEach(item => {
        if (item.url === url || item.name === name) {
          return;
        }
        let tsgObj = {
          url: url,
          name: name
        };
        if (tsgObj.url === null || tsgObj.name === null) {
          return;
        }
        this.tags.push(tsgObj);
      });
    },
    //获取路由name
    savepath(path) {
      //存入sessioStorage 方便下次刷新的时候进行调用
      setStore("selectPath", path.url);
      setStore("selectPathName", path.name);
      this.selectPath = getStore("selectPath");
      this.array.push(path);
      for (var i = 0; i < this.array.length; i++) {
        var flag = true;
        for (var j = 0; j < this.tags.length; j++) {
          if (this.array[i].name === this.tags[j].name) {
            flag = false;
          }
        }
        if (flag) {
          this.tags.push(this.array[i]);
        }
      }
    },
    //tag页点击跳转
    onClick(tag) {
      setStore("selectPath", tag.url);
      setStore("selectPathName", tag.name);
      let url = getStore("selectPath");
      this.selectPath = url;
      this.$router.push(url);
    },
    //关闭tag页
    handleClose(tag) {
      this.tags.forEach((item, index) => {
        if (item.name === tag.name) {
          this.tags.splice(index, 1);
        }
      });
      this.array.forEach((items, index) => {
        if (items.name === tag.name) {
          this.array.splice(index, 1);
        }
      });
      let arr = this.tags;
      var arrmax = arr[arr.length - 1];
      setStore("selectPath", arrmax.url);
      setStore("selectPathName", arrmax.name);
      let name = getStore("selectPath");
      this.selectPath = name;
      this.$router.push(name);
    },
    //退出
    exit() {
      removeStore("token");
      this.$router.push("/login");
    },
    btnStore() {
      maintainStore().then(res => {});
    },
    fileNotice() {
      this.dialogVisible2 = true;
      getNoticeTopMarquee().then(res => {
        this.form.content = res.data.content;
        if (res.data.turnOff == true) {
          this.form.turnOff = "1";
        } else {
          this.form.turnOff = "0";
        }
      });
    },
    noticeBtn(formName) {
      if (this.form.turnOff == "0") {
        this.form.turnOff = 0;
      } else {
        this.form.turnOff = 1;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          editNoticeTopMarquee(this.form).then(res => {
            if (res.status == 10000) {
              this.dialogVisible2 = false;
              this.$notify({
                title: "成功",
                message: "修改公告成功",
                type: "success",
                duration: 1000
              });
            }
          });
        } else {
          return false;
        }
      });
      this.form.turnOff = String(this.form.turnOff);
    }
  }
};
</script>
<style lang="less" scoped>
.holiday{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .text{
    display: inline-block;
    width: 130px;
    margin-right: 10px;
  }
  .point{
    width: 100%;
    margin-left: 5px;
    margin-top: 5px;
    color: red;
  }
}
.demonstration{
  margin-right: 30px;
}
.el-button--small,
.el-button--small.is-round {
  padding: 10px 15px;
}

.box_text {
  margin-top: 20px;
  text-align: center;
}

.iconimg {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  margin-left: 44px;
}

.box {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
}

.el-header,
.el-footer {
  background-color: #3c8dbc;
  color: #333;
}

.content {
  margin-right: 30px;
}

.contentHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
}

.tag_box .el-tag {
  padding: 10px 20px;
  line-height: 25px;
  font-size: 14px;
  border-radius: 3px;
}

.User {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2c2c2c;
}

.User > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: blue;
}

.User > span {
  margin-left: 5px;
}

.icon {
  margin-right: 30px;
  display: flex;
  position: relative;

  img {
    color: #ffffff;
  }
}

.el-icon-bell {
  font-size: 25px;
}

.header_information {
  width: 15px;
  height: 15px;
  background-color: red;
  position: absolute;
  left: 15px;
  top: 0px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  font-size: 13px;
  color: #fafafa;
  font-family: PingFang-SC-Regular;
}

.el_menu {
  width: 244px;
  border-right: 1px solid #367fa9;
}

.el-aside {
  background: #1d4277;
  color: #333;
  height: 100%;
}

i {
  margin-left: 40px;
}

.el-menu-item-group {
  font-size: 16px;
  text-align: left;
  margin-left: 50px;
}

.item_name {
  margin-left: 16px;
}

span {
  display: inline;
  margin-left: 10px;
  font-size: 16px;
}

.el-menu-item {
  font-size: 16px;
}

.aside_box_top {
  height: 70px;
  display: flex;

  .aside_logo {
    width: 60px;
    height: 60px;
    margin-top: 10px;
    margin-left: 28px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .aside_logofont {
    margin-top: 35px;
    margin-left: 10px;
    font-size: 20px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #fafafa;
  }
}

.aside_font {
  width: 155px;
  height: 50px;
  background: #ffffff;
  margin-top: 30px;
  border-radius: 0px 25px 25px 0px;
  text-align: center;
  line-height: 50px;
  color: #1d4277;
  font-size: 17px;
  font-family: PingFang SC;
}

.nav_left {
  margin-top: 20px;
}

.el-main {
  background: #f3f3f4;
  color: #333;
  padding: 10px;
  padding-top: 0px;
  width: 100%;
  height: 100vh !important;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.popover_box {
  height: 100%;
  font-size: 15px;
}

.popover {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover:hover {
  color: blue;
  cursor: pointer;
}

.tag_box {
  height: 46.6px;
  padding-bottom: 5px;
}
</style>
<style lang="less">
.tag_box {
  .el-card__body {
    // padding: 10px;
  }

  .el-tag {
    // padding: 15px 20px !important;
    line-height: 20px !important;
    font-size: 14px;
    border-radius: 3px;
  }

  span {
    margin: 0;
    margin-right: 5px !important;
    line-height: 10px !important;
    height: 40px;
  }
}

.tag {
  padding: 3px;
  display: flex;
  align-items: center;
}

div .tag_box {
  padding: 0px !important;
}

.el-submenu__title > i {
  margin-right: 20px;
  font-size: 15px;
  font-weight: bold;
  color: white !important;
}

.iconimg {
  margin-left: 0px;
}

.el-aside {
  width: 200px;
}
</style>
