import axios from 'axios'
import {
  Notification
} from 'element-ui'
import contentType from "@/const/request/requestHeader"
import Config from '@/settings'
import router from '@/router'
import {
  getStore,
  removeStore
} from './storage.js'
import method from "@/const/request/requestMethod";
import qs from 'qs'

// 创建axios实例
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: Config.timeout, // 请求超时时间
})

// request拦截器
request.interceptors.request.use(
  config => {
    let token = getStore('token')
    if (token) {
      config.headers[Config.TokenKey] = token // 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
      // 本地无token缓存，跳转登录页面
      router.push({
        path: '/Login'
      })
    }
    if (config.headers['Content-Type'] === contentType.formURL) {
      config.data = qs.stringify(config.data)
    } else {
      config.headers['Content-Type'] = contentType.formJSON
    }
    // 封装get请求url参数
    if (config.method === method.GET) {
      if (config.data) {
        config.url = config.url + '?' + qs.stringify(config.data);
      }
    }
    return config
  },
  error => {
    Promise.reject(error).catch((e) => {})
  }
)

// response拦截器
request.interceptors.response.use(
  response => {
    let status = response.data.status;
    localStorage.setItem('status', status)
    let requestType = response.config.requestType;
    if (requestType && response.data.data === false) {
      Notification.error({
        title: "错误",
        message: requestType + "失败",
        position: 'top-right',
        type: "error",
      })
      return Promise.reject(response.data.msg).catch((e) => {})
    }
    if (status !== 10000 && response.status !== 200) {
      Notification.error({
        title: "错误",
        message: response.data.msg,
        position: 'top-right',
        type: "warning",
      })
      return Promise.reject(response.data.msg).catch((e) => {})
    }
    return response.data
  },
  error => {
    let httpCode = error.response.status
    let errMassage = error.response.data.msg
    let status = 0
    try {
      status = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: "警告",
          message: '网络请求超时',
          position: 'top-right',
          duration: 5000
        })
        return Promise.reject(errMassage).catch((e) => {})
      }
    }
    if (httpCode === 404) {
      router.push({
        path: '/404'
      })
    } else {
      if (status !== 10000 && status === 20001) {
        Notification.error({
          title: '错误',
          message: errMassage,
          position: 'top-right',
          duration: 3000
        })
        // 无效的token  跳转登录页面并且删除当前token
        router.push({
          path: '/Login'
        })
        removeStore("token")
      } else {
        Notification.error({
          title: '错误',
          message: errMassage,
          position: 'top-right',
          duration: 5000
        })
      }
    }
    return Promise.reject(errMassage).catch((e) => {})
  }
)
export default request
