import request from "@/utils/request";
import loginApi from '@/const/apiUrl/login';
import method from "@/const/request/requestMethod";

// 获取验证码
export function getCaptcha() {
    return request({
        url: loginApi.getCaptcha,
        method: method.GET
    })
}

// 登录
export function login(loginInfo) {
    return request({
        url: loginApi.Login,
        method: method.POST,
        data: loginInfo
    })
}
