const BASE_URI = process.env.VUE_APP_BASE_API;
const url_image = BASE_URI + '/upload/image';
const url_license = BASE_URI + '/ocr/license';
const url_idcard = BASE_URI + '/ocr/idcard';
const url_agreement = BASE_URI +'/upload/agreement';
const url_file =BASE_URI +'/upload/file'
const url_privacy = BASE_URI +'/upload/privacy/image'
export default {
  url_image,
  url_license,
  url_idcard,
  url_agreement,
  url_file,
  url_privacy
}
