module.exports = {
  /**
   * @description 网站标题
   */
  title: '新发地掌鲜',
  /**
   * @description token key
   */
  TokenKey: 'Authorization',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 1200000,
  /**
   * 底部文字，支持html语法
   */
  footerTxt: '© 2021 新发地掌鲜 <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a>',
  /**
   * 备案号
   */
  caseNumber: '京ICP备18005431号'
}
