import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { box } from "./utils/MessageBox";
import './assets/css/reset.css'
import './assets/iconfont/iconfont.css'
import qs from 'qs'

Vue.prototype._box = box;
// qs转化传输数据
Vue.prototype.$qs = qs
Vue.use(ElementUI);
// 开启之后，不会生产调试信息
Vue.config.productionTip = process.env.PRODUCTION_TIP


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')



