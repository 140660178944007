const fileApi = {
    // 上传图片
    uploadImage: '/upload/image',
    ocrIdcard: '/ocr/idcard',
    license:'/ocr/license',
    privacy:'/upload/privacy/image',
    //上传公告
    editNoticeTopMarquee:'/setting/notice/editNoticeTopMarquee',
    //获取上传公告
    getNoticeTopMarquee:'/setting/notice/getNoticeTopMarquee'
}


export default fileApi
