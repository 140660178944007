<template>
  <div class="box_home">
    <div class="home_font">欢迎进入本系统</div>
    <div class="box">
      <div class="home_img">
        <img src="../../assets/images/welcome.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { getStore } from "@/utils/storage";
import router from "@/router";
export default {
  created() {
    this.getToken();
  },
  methods: {
    getToken() {
      if (!getStore("token")) {
        router.push("/login");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.box_home {
  width: 100%;
  position: relative;
}
.home_font {
  margin-top: 80px;
  text-align: center;
  color: #000000;
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
}
.home_img {
  position: absolute;
  top: 250%;
  left: 50%;
  transform: translate(-50%);
  width: 479px;
  height: 587px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
