<template>
  <div class="body">
    <div class="One"></div>
    <div class="Two"></div>
    <div class="box_center">
      <div class="box_login">
        <img src="../../assets/images/logos.png" alt="" />
      </div>
      <div class="login_font">新发地掌鲜后台管理系统</div>
      <div style="width: 400px; margin: 0 auto">
        <!-- login -->
        <el-form
          style="margin-top: 50px"
          :model="ruleForm"
          :rules="rules"
          ref="loginForm"
          class="demo-ruleForm"
        >
          <el-form-item label="" prop="username">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.username"
              placeholder="请填写用户名"
            >
            </el-input>
          </el-form-item>
          <div style="height: 10px"></div>
          <el-form-item label="" prop="password">
            <el-input
              type="password"
              prefix-icon="el-icon-lock"
              v-model="ruleForm.password"
              placeholder="请填写密码"
            ></el-input>
          </el-form-item>
          <div class="round_box">
            <div class="round_left">
              <el-form-item label="" prop="captcha">
                <el-input
                  prefix-icon="el-icon-map-location"
                  v-model="ruleForm.captcha"
                  placeholder="请填写验证码"
                ></el-input>
              </el-form-item>
            </div>
            <div class="round_right" @click="loginList">
              <img :src="verification" alt="" />
            </div>
          </div>
          <el-form-item>
            <el-button
              class="login_btn"
              type="primary"
              v-on:click="submitForm('loginForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
        <!-- login -->
      </div>
    </div>
  </div>
</template>
<script>
import { login, getCaptcha } from "@/api/login";
import { setStore } from "@/utils/storage";
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
        sign: "",
        captcha: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不正确", trigger: "blur" },
        ],
        password: [{ required: true, message: "密码不正确", trigger: "blur" }],
      },
      verification: "",
    };
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  created() {
    this.loginList();
  },
  methods: {
    // 获取验证
    loginList() {
      getCaptcha().then((res) => {
        let that = this;
        that.verification = res.data.data;
        that.ruleForm.sign = res.data.sign;
      });
    },
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((reg) => {
        if (reg) {
          let self = this;
          login(self.ruleForm).then((res) => {
            if (res.status === 10000) {
              setStore("token", res.data);
              this.$router.push("/home");
            }
          });
        }
      });
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode === 13) {
        login(this.ruleForm).then((res) => {
          if (res.status === 10000) {
            setStore("token", res.data);
            this.$router.push("/home");
            window.removeEventListener("keydown", this.keyDown, false);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #90caf9, #047edf);
}
.One {
  width: 353px;
  height: 353px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  position: absolute;
  right: -200px;
  top: -50px;
}
.Two {
  width: 497px;
  height: 497px;
  box-shadow: 0px 0px 135px 15px #7bc7f7;
  border-radius: 50%;
  background: #7cc7f7;
  background: rgba(255, 255, 255, 0.16);
  position: absolute;
  left: -200px;
  bottom: -100px;
}
.box {
  width: 512px;
  height: 687px;
  background: #ffffff;
  box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.17);
  border-radius: 30px;
}
.logo {
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: center;
  padding-top: 113px;
}
.logo > img {
  width: 118px;
  height: 112px;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #279dfb;
  padding-top: 31px;
  padding-bottom: 56px;
}
.input {
  width: 398px;
  height: 52px;
  margin: 0 auto;
  margin-bottom: 45px;
}
.btn {
  width: 398px;
  height: 51px;
  margin: 0 auto;
}
.box_center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 512px;
  height: 687px;
  background: #ffffff;
  box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.17);
  border-radius: 30px;
  position: absolute;
  .box_login {
    width: 118px;
    height: 112px;
    margin: 0 auto;
    margin-top: 100px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .login_font {
    width: 396px;
    height: 80px;
    line-height: 110px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    margin: 0 auto;
    color: #279dfb;
  }
  .login_btn {
    width: 398px;
    height: 51px;
    background: #279dfb;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
}
.round_box {
  display: flex;
  justify-content: space-between;
}
.round_left {
  margin-top: 5px;
  width: 250px;
  height: 50px;
}
.round_right {
  width: 120px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
